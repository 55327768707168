import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

//country
const getUserGroupTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/user-group-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllUserGroupTypes = async () => {
  const response = await api.get(`${servicePrefix}/user-group-types/all`)
  return response
}

const getUserGroupTypeById = async (id) => {
  const response = await api.get(`${servicePrefix}/user-group-types/${id}`)
  return response.data
}

const addUserGroupTypes = async (groupType) => {
  const response = await api.post(`${servicePrefix}/user-group-types`, groupType)
  return response.data
}

const updateUserGroupTypes = async (groupType) => {
  const { id } = groupType
  const response = await api.patch(`${servicePrefix}/user-group-types/${id}`, groupType)
  return response.data
}

const deleteUserGroupTypes = async (id) => {
  const response = await api.delete(`${servicePrefix}/user-group-types/${id}`)
  return response.data
}

const getAllUserGroups = async () => {
  const response = await api.get(`${servicePrefix}/user-groups/all`)
  return response
}

const UserGroupTypeService = {
  getUserGroupTypes,
  getAllUserGroupTypes,
  getUserGroupTypeById,
  addUserGroupTypes,
  updateUserGroupTypes,
  deleteUserGroupTypes,
  getAllUserGroups
}

export default UserGroupTypeService
