// ** Icons Import
import { Check, Circle, User, Users } from 'react-feather'

export default [
  {
    id: 'app-users',
    title: 'Subscribers',
    icon: <Users size={20} />,
    children: [
      {
        id: 'list',
        title: 'Individual List',
        icon: <Check size={12} />,
        navLink: '/subscriber/list'
      },
      {
        id: 'admin-list',
        title: 'Organization Admin',
        icon: <Check size={12} />,
        navLink: '/admin-subscriber/list'
      },
      {
        id: 'subscriber-list',
        title: 'Import Media House Subscribers',
        icon: <Check size={12} />,
        navLink: '/media-house-subscriber/upload'
      }
    ]
  }
]
