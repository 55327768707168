import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getRoles = async (params) => {
  const response = await api.get(`${servicePrefix}/roles`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllRoles = async () => {
  const response = await api.get(`${servicePrefix}/roles/all`)
  return response.data
}

const getRoleById = async (id) => {
  const response = await api.get(`${servicePrefix}/roles/${id}`)
  return response.data
}

const addRole = async (role) => {
  const response = await api.post(`${servicePrefix}/roles`, role)
  return response.data
}

const updateRole = async (role) => {
  const { id } = role

  const response = await api.patch(`${servicePrefix}/roles/${id}`, role)
  return response.data
}

const deleteRole = async (id) => {
  const response = await api.delete(`${servicePrefix}/roles/${id}`)
  return response.data
}

const RoleService = {
  getRoles,
  getAllRoles,
  getRoleById,
  addRole,
  updateRole,
  deleteRole
}

export default RoleService
