import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

//country
const getPlans = async (params) => {
  const response = await api.get(`${servicePrefix}/plans`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllPlans = async () => {
  const response = await api.get(`${servicePrefix}/plans/all`)
  return response
}

const getPlanById = async (id) => {
  const response = await api.get(`${servicePrefix}/plans/${id}`)
  return response.data
}

const addPlan = async (plan) => {
  const response = await api.post(`${servicePrefix}/plans`, plan)
  return response.data
}

const updatePlan = async (plan) => {
  const { id } = plan
  const response = await api.patch(`${servicePrefix}/plans/${id}`, plan)
  return response.data
}

const deletePlan = async (id) => {
  const response = await api.delete(`${servicePrefix}/plans/${id}`)
  return response.data
}

const getPlanHistoriesBySubscription = async (userGroupId) => {
  const response = await api.get(`${servicePrefix}/plan-histories/subscriptions/${userGroupId}`)
  return response.data
}

const PlanService = {
  getPlans,
  getAllPlans,
  getPlanById,
  addPlan,
  updatePlan,
  deletePlan,
  getPlanHistoriesBySubscription
}

export default PlanService
