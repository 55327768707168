// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import EditionService from '@services/edition'

export const getEditions = createAsyncThunk('edition/getEditions', async (params, { rejectWithValue }) => {
  try {
    const response = await EditionService.getEditions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getEditionById = createAsyncThunk('edition/getEditionById', async (id) => {
  const response = await EditionService.getEditionById(id)
  console.log(response)
  return response
})

export const addEdition = createAsyncThunk('edition/addEdition', async (edition, { rejectWithValue }) => {
  try {
    const response = await EditionService.addEdition(edition)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateEdition = createAsyncThunk('edition/updateEdition', async (edition, { rejectWithValue }) => {
  try {
    const response = await EditionService.updateEdition(edition)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteEdition = createAsyncThunk('edition/deleteEdition', async (id) => {
  const response = await EditionService.deleteEdition(id)
  return response
})

export const editionSlice = createSlice({
  name: 'editions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedEdition: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEditions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getEditionById.fulfilled, (state, action) => {
        state.selectedEdition = action.payload
        state.loading = false
      })
      .addCase(getEditionById.pending, (state, action) => {
        state.selectedEdition = action.payload
        state.loading = true
      })
  }
})

export default editionSlice.reducer
