// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserGroupTypeService from '@services/user-group-type'

export const getUserGroupTypes = createAsyncThunk('userGroupTypes/getUserGroupTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await UserGroupTypeService.getUserGroupTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getUserGroupTypeById = createAsyncThunk('userGroupTypes/getUserGroupTypeById', async (id) => {
  const response = await UserGroupTypeService.getUserGroupTypeById(id)
  return response
})

export const addUserGroupTypes = createAsyncThunk('userGroupTypes/addUserGroupTypes', async (inGroups, { rejectWithValue }) => {
  try {
    const response = await UserGroupTypeService.addUserGroupTypes(inGroups)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateUserGroupTypes = createAsyncThunk('userGroupTypes/updateUserGroupTypes', async (inGroups, { rejectWithValue }) => {
  try {
    const response = await UserGroupTypeService.updateUserGroupTypes(inGroups)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteUserGroupTypes = createAsyncThunk('userGroupTypes/deleteUserGroupTypes', async (id, { dispatch, getState }) => {
  await UserGroupTypeService.deleteUserGroupTypes(id)
  await dispatch(getUserGroupTypes(getState()?.inGroups.params))
  return id
})

export const groupTypeSlice = createSlice({
  name: 'userGroupTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualUserGroupType: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserGroupTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUserGroupTypeById.fulfilled, (state, action) => {
        state.individualUserGroupType = action.payload
        state.loading = false
      })
  }
})

export default groupTypeSlice.reducer
