// ** React Imports
import { lazy } from 'react'

const Roles = lazy(() => import('../../views/role/list/index'))
const RoleAddForm = lazy(() => import('../../views/role/add/index'))
const RoleUpdateForm = lazy(() => import('../../views/role/edit/index'))
const Permissions = lazy(() => import('../../views/permission/list/index'))
const PermissionAddForm = lazy(() => import('../../views/permission/add/index'))
const PermissionUpdateForm = lazy(() => import('../../views/permission/edit/index'))

const RolePermissionRoutes = [
  {
    element: <Roles />,
    path: '/roles/list'
  },
  {
    path: '/roles/add',
    element: <RoleAddForm />
  },
  {
    path: '/roles/edit/:id',
    element: <RoleUpdateForm />
  },
  {
    element: <Permissions />,
    path: '/permissions/list'
  },
  {
    path: '/permissions/add',
    element: <PermissionAddForm />
  },
  {
    path: '/permissions/edit/:id',
    element: <PermissionUpdateForm />
  }
]

export default RolePermissionRoutes
