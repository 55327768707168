// ** Icons Import
import { Circle, User } from 'react-feather'

export default [
  {
    id: 'users',
    title: 'User',
    icon: <User size={20} />,
    children: [
      {
        id: 'list',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/user/list'
      }
    ]
  }
]
