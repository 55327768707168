// ** React Imports
import { lazy } from 'react'

const InGroupList = lazy(() => import('../../views/in-group/list'))
const MediaHouseAddForm = lazy(() => import('../../views/media-house/add/index'))
const MediaHouseUpdateForm = lazy(() => import('../../views/media-house/edit/index'))

const InGroupRoutes = [
  {
    element: <InGroupList />,
    path: '/in-group/list'
  },
  {
    path: '/in-group/add',
    element: <MediaHouseAddForm />
  },
  {
    path: '/in-group/edit/:id',
    element: <MediaHouseUpdateForm />
  }
]

export default InGroupRoutes
