// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const ReportList = lazy(() => import('../../views/payment/list'))

const ReportRoutes = [
  {
    element: <ReportList />,
    path: '/report/payments/list'
  }
]

export default ReportRoutes
