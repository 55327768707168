// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const MediaHouseList = lazy(() => import('../../views/media-house/list'))
// const MediaHouseView = lazy(() => import('../../views/media-house/index'))
const MediaHouseAddForm = lazy(() => import('../../views/media-house/add/index'))
const MediaHouseUpdateForm = lazy(() => import('../../views/media-house/edit/index'))

const MediaHouseRoutes = [
  {
    element: <MediaHouseList />,
    path: '/media-house/list'
  },
  {
    path: '/media-house/add',
    element: <MediaHouseAddForm />
  },
  // {
  //   path: '/media-house/view/:id',
  //   element: <MediaHouseView />
  // },
  {
    path: '/media-house/edit/:id',
    element: <MediaHouseUpdateForm />
  }
]

export default MediaHouseRoutes
