// ** Icons Import
import { Check, Monitor } from 'react-feather'

export default [
  {
    id: 'reports',
    title: 'Reports',
    icon: <Monitor size={20} />,
    children: [
      {
        id: 'list',
        title: 'Payments',
        icon: <Check size={12} />,
        navLink: '/report/payments/list'
      }
    ]
  }
]
