// ** React Imports
import { lazy } from 'react'

const LoginBasic = lazy(() => import('../../views/authentication/LoginBasic'))

const ForgotPasswordBasic = lazy(() => import('../../views/authentication/ForgotPasswordBasic'))

const VerifyOTP = lazy(() => import('../../views/authentication/VerifyOTP'))

const ResetPasswordBasic = lazy(() => import('../../views/authentication/ResetPasswordBasic'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <LoginBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordBasic />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/verify-number',
    element: <VerifyOTP />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/reset-password',
    element: <ResetPasswordBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }
]

export default AuthenticationRoutes
