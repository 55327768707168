// ** React Imports
import { lazy } from 'react'

const PlanList = lazy(() => import('../../views/plan/list'))
const PlanAddForm = lazy(() => import('../../views/plan/add/index'))
const PlanUpdateForm = lazy(() => import('../../views/plan/edit/index'))

const PlanRoutes = [
  {
    element: <PlanList />,
    path: '/plan/list'
  },
  {
    path: '/plan/add',
    element: <PlanAddForm />
  },
  {
    path: '/plan/edit/:id',
    element: <PlanUpdateForm />
  }
]

export default PlanRoutes
