// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SubscriberService from '@services/subscriber'
import InvoiceService from '@services/invoice'

export const getSubscribers = createAsyncThunk('user/getSubscribers', async (params, { rejectWithValue }) => {
  try {
    const response = await SubscriberService.getSubscribers(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAdminSubscribers = createAsyncThunk('user/getAdminSubscribers', async (params, { rejectWithValue }) => {
  try {
    const response = await SubscriberService.getAdminSubscribers(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getSubscriberById = createAsyncThunk('user/getSubscriberById', async (id) => {
  const response = await SubscriberService.getSubscriberById(id)
  return response
})

export const userBulkUpload = createAsyncThunk('user/userBulkUpload', async (data, { rejectWithValue }) => {
  try {
    const response = await SubscriberService.userBulkUpload(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateInvoicePayment = createAsyncThunk('invoice/updatePayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await InvoiceService.updatePaymentInvoice(payment)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const mediaHouseSubscriberUpload = createAsyncThunk('user/mediaHouseSubscriberUpload', async (data, { rejectWithValue }) => {
  try {
    const response = await SubscriberService.mediaHouseSubscriberUpload(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const subscriberSlice = createSlice({
  name: 'subscribers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualSubscriber: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscribers.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAdminSubscribers.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getSubscriberById.fulfilled, (state, action) => {
        state.individualSubscriber = action.payload
        state.loading = false
      })
  }
})

export default subscriberSlice.reducer
