// ** React Imports
import { lazy } from 'react'

const UserGroupTypeList = lazy(() => import('../../views/user-group-type/list'))
const UserGroupTypeEditForm = lazy(() => import('../../views/user-group-type/edit/index'))
const UserGroupTypeAddForm = lazy(() => import('../../views/user-group-type/add/index'))

const UserGroupTypeRoutes = [
  {
    element: <UserGroupTypeList />,
    path: '/user-group-type/list'
  },
  {
    path: '/user-group-type/add',
    element: <UserGroupTypeAddForm />
  },
  // {
  //   path: '/media-house/view/:id',
  //   element: <MediaHouseView />
  // },
  {
    path: '/user-group-type/edit/:id',
    element: <UserGroupTypeEditForm />
  }
]

export default UserGroupTypeRoutes
