// ** Icons Import
import { Circle, Shield } from 'react-feather'
import { FaDollarSign } from 'react-icons/fa'

export default [
  {
    id: 'subscription',
    title: 'Subscription Plans',
    icon: <FaDollarSign size={12} />,
    // permissions: ['admin', 'editor'],
    // navLink: '/subscription-plan'
    children: [
      // {
      //   id: 'Group list',
      //   title: 'User Group Type',
      //   icon: <Circle size={12} />,
      //   navLink: '/user-group-type/list'
      // },
      {
        id: 'Plan List',
        title: 'Plan',
        icon: <Circle size={12} />,
        navLink: '/plan/list'
      }
    ]
  }
]

// {
//     id: 'pricing',
//     title: 'Pricing',
//     icon: <Circle size={12} />,
//     permissions: ['admin', 'editor'],
//     navLink: '/pages/pricing'
//   },
