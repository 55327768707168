// ** React Imports

import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const Subscription = lazy(() => import('../../views/subscription'))
const InvoicePreview = lazy(() => import('../../views/subscriber/invoice/index'))

const SubscriptionRoutes = [
  {
    path: '/subscription-plan',
    element: <Subscription />
  },
  {
    path: '/subscription-plan/invoice',
    element: <Navigate to="/apps/invoice/preview/4987" />
  },
  {
    path: '/subscription/:subscriptionId/invoice',
    element: <InvoicePreview />
  }
]

export default SubscriptionRoutes
