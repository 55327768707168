import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getPayments = async (params) => {
  const response = await api.get(`${servicePrefix}/payments`, { params: stripEmptyObjects(params) })
  return response.data
}

const getPaymentById = async (id) => {
  const response = await api.get(`${servicePrefix}/payments/${id}`)
  return response.data
}

const PaymentService = {
  getPayments,
  getPaymentById
}

export default PaymentService
