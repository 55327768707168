import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getEditions = async (params) => {
  const response = await api.get(`${servicePrefix}/editions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getEditionById = async (id) => {
  const response = await api.get(`${servicePrefix}/editions/${id}`)
  return response.data
}

const addEdition = async (edition) => {
  const response = await api.post(`${servicePrefix}/editions`, edition, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  console.log(response)
  return response.data
}

const updateEdition = async (edition) => {
  const { id } = edition

  const response = await api.patch(`${servicePrefix}/editions/${id}`, edition)
  return response.data
}

const deleteEdition = async (id) => {
  const response = await api.delete(`${servicePrefix}/editions/${id}`)
  return response.data
}

const EditionService = {
  getEditions,
  getEditionById,
  addEdition,
  updateEdition,
  deleteEdition
}

export default EditionService
