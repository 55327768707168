import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getUsers = async (params) => {
  const response = await api.get(`${servicePrefix}/admin-users`, { params: stripEmptyObjects(params) })
  return response.data
}

const getUserById = async (id) => {
  const response = await api.get(`${servicePrefix}/admin-users/${id}`)
  return response.data
}

const addUser = async (user) => {
  const response = await api.post(`${servicePrefix}/admin-users`, user)
  return response.data
}

const userPasswordChange = async (password) => {
  const response = await api.post(`${servicePrefix}/admin-users/change-password`, password)
  return response.data
}

const updateUser = async (user) => {
  const { id } = user
  const response = await api.patch(`${servicePrefix}/admin-users/${id}`, user)
  return response.data
}

const forgotPassword = async (email) => {
  const response = await api.post(`${servicePrefix}/admin-users/forgot-password/${email}`)
  return response.data
}

const verifyOtp = async (otp, accessToken) => {
  const response = await api.get(`${servicePrefix}/admin-users/verify/${otp}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.data
}

const resetPassword = async (data, accessToken) => {
  const response = await api.post(`${servicePrefix}/admin-users/reset-password`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.data
}

const UserService = {
  getUsers,
  getUserById,
  addUser,
  userPasswordChange,
  updateUser,
  forgotPassword,
  verifyOtp,
  resetPassword
}

export default UserService
