// ** React Imports
import { lazy } from 'react'

const SubscriberList = lazy(() => import('../../views/subscriber/list'))
const SubscriberView = lazy(() => import('../../views/subscriber/view/index'))
const AdminSubscriberList = lazy(() => import('../../views/subscriber/admin-list'))
const AdminSubscriberView = lazy(() => import('../../views/subscriber/admin-view/index'))
const ImportUser = lazy(() => import('../../views/subscriber/upload-user/index'))
const ImportSubscriber = lazy(() => import('../../views/subscriber/upload-subscribers/index'))

const SubscriberRoutes = [
  {
    element: <SubscriberList />,
    path: '/subscriber/list'
  },
  {
    element: <SubscriberView />,
    path: '/subscriber/view/:id'
  },
  {
    element: <AdminSubscriberList />,
    path: '/admin-subscriber/list'
  },
  {
    element: <AdminSubscriberView />,
    path: '/admin-subscriber/view/:id'
  },
  {
    path: '/subscriber/upload',
    element: <ImportUser />
  },
  {
    path: '/media-house-subscriber/upload',
    element: <ImportSubscriber />
  }
]

export default SubscriberRoutes
