// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import InGroupService from '@services/in-group'

export const getInGroups = createAsyncThunk('inGroups/getInGroups', async (params, { rejectWithValue }) => {
  try {
    const response = await InGroupService.getMediaHouses(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getInGroupById = createAsyncThunk('inGroups/getInGroupById', async (id) => {
  const response = await InGroupService.getMediaHouseById(id)
  return response
})

export const addInGroups = createAsyncThunk('inGroups/addInGroups', async (inGroups, { rejectWithValue }) => {
  try {
    const response = await InGroupService.addMediaHouse(inGroups)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateInGroups = createAsyncThunk('inGroups/updateInGroups', async (inGroups, { rejectWithValue }) => {
  try {
    const response = await InGroupService.updateMediaHouse(inGroups)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteInGroups = createAsyncThunk('inGroups/deleteInGroups', async (id, { dispatch, getState }) => {
  await InGroupService.deleteMediaHouse(id)
  await dispatch(getMediaHouses(getState()?.inGroups.params))
  return id
})

export const roleSlice = createSlice({
  name: 'inGroups',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualInGroup: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInGroups.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getInGroupById.fulfilled, (state, action) => {
        state.individualInGroup = action.payload
        state.loading = false
      })
  }
})

export default roleSlice.reducer
