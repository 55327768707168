// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PlanService from '@services/plan'

export const getPlans = createAsyncThunk('plans/getPlans', async (params, { rejectWithValue }) => {
  try {
    const response = await PlanService.getPlans(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getPlanById = createAsyncThunk('plans/getPlanById', async (id) => {
  const response = await PlanService.getPlanById(id)
  return response
})

export const addPlan = createAsyncThunk('plans/addPlan', async (plans, { rejectWithValue }) => {
  try {
    const response = await PlanService.addPlan(plans)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePlan = createAsyncThunk('plans/updatePlan', async (plans, { rejectWithValue }) => {
  try {
    const response = await PlanService.updatePlan(plans)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deletePlan = createAsyncThunk('plans/deletePlan', async (id, { dispatch, getState }) => {
  await PlanService.deletePlan(id)
  await dispatch(getPlans(getState()?.plans.params))
  return id
})

export const planSlice = createSlice({
  name: 'plans',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualPlan: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlans.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPlanById.fulfilled, (state, action) => {
        state.individualPlan = action.payload
        state.loading = false
      })
  }
})

export default planSlice.reducer
