import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

//country
const getinGroups = async (params) => {
  const response = await api.get(`${servicePrefix}/in-groups`, { params: stripEmptyObjects(params) })
  return response.data
}

const getinGroupById = async (id) => {
  const response = await api.get(`${servicePrefix}/in-groups/${id}`)
  return response.data
}

const addInGroups = async (inGroup) => {
  const response = await api.post(`${servicePrefix}/in-groups`, inGroup)
  return response.data
}

const updateInGroups = async (inGroup) => {
  const { id } = inGroup
  const response = await api.patch(`${servicePrefix}/in-groups/${id}`, inGroup)
  return response.data
}

const deleteInGroups = async (id) => {
  const response = await api.delete(`${servicePrefix}/in-groups/${id}`)
  return response.data
}

const InGroupService = {
  getinGroups,
  getinGroupById,
  addInGroups,
  updateInGroups,
  deleteInGroups
}

export default InGroupService
