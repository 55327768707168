import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getInvoices = async (params) => {
  const response = await api.get(`${servicePrefix}/invoices`, { params: stripEmptyObjects(params) })
  return response.data
}

const getInvoiceById = async (id) => {
  const response = await api.get(`${servicePrefix}/invoices/${id}`)
  return response.data
}

const getInvoiceBySubscriptionId = async (subscriptionId) => {
  const response = await api.get(`${servicePrefix}/invoices/get-invoice-by-subscription/${subscriptionId}`)
  return response.data
}

const updatePaymentInvoice = async (data) => {
  const { invoiceId } = data
  const response = await api.patch(`${servicePrefix}/invoices/update-payment/${invoiceId}`, data)
  return response.data
}

const InvoiceService = {
  getInvoices,
  getInvoiceById,
  getInvoiceBySubscriptionId,
  updatePaymentInvoice
}

export default InvoiceService
