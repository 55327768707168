// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import users from '@src/views/user/store'
import permissions from '@src/views/permission/store'
import roles from '@src/views/role/store'
import editions from '@src/views/edition/store'
import mediaHouses from '@src/views/media-house/store'
import inGroups from '@src/views/in-group/store'
import userGroupTypes from '@src/views/user-group-type/store'
import plans from '@src/views/plan/store'
import subscribers from '@src/views/subscriber/store'
import payments from '@src/views/payment/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  users,
  permissions,
  roles,
  editions,
  mediaHouses,
  inGroups,
  userGroupTypes,
  plans,
  subscribers,
  payments
}

export default rootReducer
