// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PaymentService from '@services/payment'

export const getPayments = createAsyncThunk('user/getPaymenta', async (params, { rejectWithValue }) => {
  try {
    const response = await PaymentService.getPayments(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getPaymentById = createAsyncThunk('user/getPaymentById', async (id) => {
  const response = await PaymentService.getPaymentById(id)
  return response
})

export const paymentSlice = createSlice({
  name: 'payments',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualPayment: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPaymentById.fulfilled, (state, action) => {
        state.individualPayment = action.payload
        state.loading = false
      })
  }
})

export default paymentSlice.reducer
