// ** Icons Import
import { Circle, Shield } from 'react-feather'
import { FaNewspaper } from 'react-icons/fa'

export default [
  {
    id: 'media-houses',
    title: 'Media Houses',
    icon: <FaNewspaper size={20} />,
    children: [
      {
        id: 'list',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/media-house/list'
      }
    ]
  }
]
