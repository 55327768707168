// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const EditionList = lazy(() => import('../../views/edition/list'))
const EditionView = lazy(() => import('../../views/edition/view/index'))
const UploadFile = lazy(() => import('../../views/edition/uploadEdition/index'))
const EditionForm = lazy(() => import('../../views/edition/edit/index'))

const EditionRoutes = [
  {
    element: <EditionList />,
    path: '/edition/list',
    meta: {
      action: 'manage',
      resource: 'Edition'
    }
  },
  {
    path: '/edition/file-upload',
    element: <UploadFile />,
    meta: {
      action: 'create',
      resource: 'Edition'
    }
  },
  {
    path: '/edition/view/:id',
    element: <EditionView />,
    meta: {
      action: 'read',
      resource: 'Edition'
    }
  },
  {
    path: '/edition/edit/:id',
    element: <EditionForm />,
    meta: {
      action: 'update',
      resource: 'Edition'
    }
  }
]

export default EditionRoutes
