// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MediaHouseService from '@services/media-house'

export const getMediaHouses = createAsyncThunk('mediaHouse/getMediaHouses', async (params, { rejectWithValue }) => {
  try {
    const response = await MediaHouseService.getMediaHouses(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getMediaHouseById = createAsyncThunk('mediaHouse/getMediaHouseById', async (id) => {
  const response = await MediaHouseService.getMediaHouseById(id)
  return response
})

export const addMediaHouse = createAsyncThunk('mediaHouse/addMediaHouse', async (role, { rejectWithValue }) => {
  try {
    const response = await MediaHouseService.addMediaHouse(role)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateMediaHouse = createAsyncThunk('mediaHouse/updateMediaHouse', async (role, { rejectWithValue }) => {
  try {
    const response = await MediaHouseService.updateMediaHouse(role)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteMediaHouse = createAsyncThunk('mediaHouse/deleteMediaHouse', async (id, { dispatch, getState }) => {
  await MediaHouseService.deleteMediaHouse(id)
  await dispatch(getMediaHouses(getState()?.mediaHouses.params))
  return id
})

export const roleSlice = createSlice({
  name: 'mediaHouses',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMediaHouse: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMediaHouses.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMediaHouseById.fulfilled, (state, action) => {
        state.individualMediaHouse = action.payload
        state.loading = false
      })
  }
})

export default roleSlice.reducer
