// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/user/list'))
const UserView = lazy(() => import('../../views/user/view/index'))
const UserAddForm = lazy(() => import('../../views/user/add/index'))
const UserUpdateForm = lazy(() => import('../../views/user/edit/index'))
const ChangePassword = lazy(() => import('../../views/user/view/ChangePassword'))

const UserRoutes = [
  {
    element: <UserList />,
    path: '/user/list'
  },
  {
    path: '/user/add',
    element: <UserAddForm />
  },
  {
    path: '/user/view/:id',
    element: <UserView />
  },
  {
    path: '/user/edit/:id',
    element: <UserUpdateForm />
  },
  {
    element: <ChangePassword />,
    path: '/user/change-password',
    meta: {
      action: 'manage',
      resource: 'Password'
    }
  }
]

export default UserRoutes
