import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

//country
const getMediaHouses = async (params) => {
  const response = await api.get(`${servicePrefix}/media-houses`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllMediaHouses = async () => {
  const response = await api.get(`${servicePrefix}/media-houses/all`)
  return response
}

const getMediaHouseById = async (id) => {
  const response = await api.get(`${servicePrefix}/media-houses/${id}`)
  return response.data
}

const addMediaHouse = async (mediaHouse) => {
  const response = await api.post(`${servicePrefix}/media-houses`, mediaHouse)
  return response.data
}

const updateMediaHouse = async (mediaHouse) => {
  const { id } = mediaHouse
  const response = await api.patch(`${servicePrefix}/media-houses/${id}`, mediaHouse)
  return response.data
}

const deleteMediaHouse = async (id) => {
  const response = await api.delete(`${servicePrefix}/media-houses/${id}`)
  return response.data
}

const MediaHouseService = {
  getMediaHouses,
  getAllMediaHouses,
  getMediaHouseById,
  addMediaHouse,
  updateMediaHouse,
  deleteMediaHouse
}

export default MediaHouseService
