// ** Navigation imports
import dashboards from './dashboards'
import rolespermissions from './roles-permissions'
import users from './users'
// import forms from './forms'
// import tables from './tables'
import editions from './editions'
import mediaHouses from './media-houses'
import subscription from './subscription'
import appUser from './app-user'
import report from './report'

// ** Merge & Export
export default [...dashboards, ...rolespermissions, ...mediaHouses, ...subscription, ...users, ...editions, ...appUser, ...report]
