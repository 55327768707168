import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getPermissions = async (params) => {
  const response = await api.get(`${servicePrefix}/permissions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllPermissions = async () => {
  const response = await api.get(`${servicePrefix}/permissions/all`)
  return response.data
}

const getPermissionById = async (id) => {
  const response = await api.get(`${servicePrefix}/permissions/${id}`)
  return response.data
}

const addPermission = async (permission) => {
  const response = await api.post(`${servicePrefix}/permissions`, permission)
  return response.data
}

const updatePermission = async (permission) => {
  const { id } = permission

  const response = await api.patch(`${servicePrefix}/permissions/${id}`, permission)
  return response.data
}

const deletePermission = async (id) => {
  const response = await api.delete(`${servicePrefix}/permissions/${id}`)
  return response.data
}

const PermissionService = {
  getPermissions,
  getAllPermissions,
  getPermissionById,
  addPermission,
  updatePermission,
  deletePermission
}

export default PermissionService
