// ** Icons Import
import { Circle, Archive } from 'react-feather'
import { FaFolderOpen } from 'react-icons/fa'

export default [
  {
    id: 'edition',
    title: 'Editions',
    icon: <FaFolderOpen size={20} />,
    children: [
      {
        id: 'list',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/edition/list',
        resource: 'Edition'
      },
      {
        id: 'upload',
        title: 'File Upload',
        icon: <Circle size={12} />,
        navLink: '/edition/file-upload',
        resource: 'Edition'
      }
    ]
  }
]
